import { render, staticRenderFns } from "./OrganismContactForm.vue?vue&type=template&id=70493e65&"
import script from "./OrganismContactForm.vue?vue&type=script&lang=js&"
export * from "./OrganismContactForm.vue?vue&type=script&lang=js&"
import style0 from "./OrganismContactForm.vue?vue&type=style&index=0&id=70493e65&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,MoleculesCommonMoleculeContactForm: require('/app/components/molecules/common/MoleculeContactForm.vue').default})
